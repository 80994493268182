import logo from "../../../assets/icons/SoftwareDevelopment/logo400_WhiteN.png";
import makeStyles from  "@mui/styles/makeStyles";

const useStyles = makeStyles({
    Logo: props => ({
        width: 47,
        ...(props.style)
    }),
})

const Logo = props => {
    const classes = useStyles(props);

    return (
        <img className={classes.Logo} src={logo} alt={"Nucleate Logo"}/>
    )
}

export default Logo;
