import makeStyles from "@mui/styles/makeStyles";
import { pxToVh, pxToVw } from "../../../../../BigBoyFunctions";


const useStyles = makeStyles({
    BackdropRectangle: props => ({
        position: "absolute",
        width: pxToVw(props.width),
        height: pxToVh(props.length),
        left: pxToVw(props.x),
        top: props.y || -300,
        // top: pxToVh(-(Math.atan(props.angle * (Math.PI/180)) * (props.width)) * 1.2),
        // top: 0,

        // background: "linear-gradient(180deg, rgba(152, 63, 248, 0.59) 0%, rgba(196, 196, 196, 0) 100%)",
        background: `linear-gradient(${props?.gradientAngle ?? 0}deg, rgba(152, 63, 248, ${props?.maxOpacity || 1}) 40%, rgba(196, 196, 196, ${props?.minOpacity ?? 0}) 110%)`,
        // background: "linear-gradient(180deg, rgba(152, 63, 248, 0.59) 0%, rgba(196, 196, 196, 0) 100%)",
        // background: "linear-gradient(180deg, red, orange)",
        borderRadius: `0px 0px ${props.borderRadius}px ${props.borderRadius}px`,
        transform: `rotate(${props?.angle !== undefined ? props.angle :  45}deg)`,
        // zIndex: 5,
    })
})

const BackdropGradientRectangle = props => {
    // const {length = 800, width = 400} = props;
    let {
        angle = 45,
        x = 0,
        y = 0,
        width = 400,
        length = 800,
        borderRadius = width / 2,
        maxOpacity = 1,
        minOpacity = 0,
        gradientAngle = 0,
    } = props;

    // const widthVw = pxToVw(width)

    const classes = useStyles({...props, angle, x, y, width, length, borderRadius, maxOpacity, minOpacity, gradientAngle });

    return <div className={classes.BackdropRectangle}/>
}

export default BackdropGradientRectangle;
