import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Animation from "../../Animations/Animation"

const useStyles = makeStyles({
    WelcomeBanner: {
        background: "linear-gradient(175.81deg, #0C1F44 0.19%, #230A44 99.91%)",
        // background: "linear-gradient(90deg, #0C1F44 0.19%, #230A44 99.91%)",
        width: "100%",
        height: "100vh",

    }
})



const WelcomeBanner = props => {
    const classes = useStyles()
    return (
        <div className={classes.WelcomeBanner}>
            <Animation/>
        </div>
    )
}

export default WelcomeBanner;