import makeStyles from '@mui/styles/makeStyles';
import {useContext} from "react";
import {EnvironmentContext} from "../../../../App/App";

const useStyles = makeStyles({
    IconContainer: props => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#1E217C",
        width: props.innerWidth > 1300 ? "25%" : props.innerWidth > 600 ? "60%" : "80%",
        height: props.innerWidth > 500 ? 450 : null,
        padding: props.innerWidth > 600 ? 50 : 25,
        margin: props.innerWidth > 600 ? 10 : 0,
        marginBottom: props.innerWidth > 600 ? 50 : 25,
        borderRadius: 10,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        boxShadow: "0 0 16px 3px rgba(0,0,0,0.10)",
        transition: "ease 0.4s",

        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
            boxShadow: "0 0 64px 10px rgba(0,0,0,0.10)",
            transition: "ease 0.2s",
            transform: "scale(1.01)",
        },
        // border: "2px solid blue",
        Icon: {
            width: "200"
        }
    }),
    textContainer: {
        maxWidth: 400,
        "& p": {
            fontSize: 20,
            // fontFamily: "Montserrat",
            // fontStyle: "normal",
        }
    }
});
const IconContainer = props => {
    const {innerWidth} = useContext(EnvironmentContext);
    const classes = useStyles({...props, innerWidth: innerWidth});

    return (
        <div className={classes.IconContainer} onClick={props.onClick}>
            <img src={props.icon} height={"200"} alt={""}/>
            <div className={classes.textContainer}>
                <h1>{props.title}</h1>
                <p>{props.body}</p>
            </div>
        </div>
    )
};

export default IconContainer;