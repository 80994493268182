import makeStyles from '@mui/styles/makeStyles';
import {IconButton} from "@mui/material";
import {ArrowForwardIos} from "@mui/icons-material";

const useStyles = makeStyles({
    NextIconButton: props => ({
        // shared between next and prev buttons
        position: "absolute",
        background: "white",
        borderRadius: props.size,
        width: props.size,
        height: props.size,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
        cursor: "pointer",
        fontWeight: "bold",
        fontSize: props.size / 2,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
        zIndex: 3,

        right: "3%",
    }),
    icon: props => ({
        position: "relative",
        left: props.size / 20,
    })
})

const NextIconButton = props => {
    const {size = 60} = props;
    const classes = useStyles({...props, size});

    return (
        <IconButton className={classes.NextIconButton} onClick={props.onClick} size="large">
            <ArrowForwardIos className={classes.icon} fontSize={"inherit"}/>
        </IconButton>
    );
}

export default NextIconButton;
