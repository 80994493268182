// import {useRef, useState} from "react";
// import { Canvas,  useFrame} from "react-three-fiber";
import { Canvas } from "react-three-fiber";
// import Box from "./Box";
import Swarm from "./Swarm";

const Animation = props => {

    return (
        <Canvas
            shadowMap
            gl={{ alpha: false, antialias: false }}
            camera={{ fov: 75, position: [0, 0, 70], near: 10, far: 150 }}
            onCreated={(state) => state.gl.setClearColor('#f0f0f0')}
        >
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            {/*<Box position={[-1.2, 0, 0]} />*/}
            {/*<Box position={[1.2, 0, 0]} />*/}
            <ambientLight intensity={1.5} />
            <pointLight position={[100, 100, 100]} intensity={2} castShadow />
            <pointLight position={[-100, -100, -100]} intensity={5} color="red" />

            <Swarm count={150}/>
        </Canvas>
    )
}

export default Animation