import {motion} from "framer-motion";
import makeStyles from '@mui/styles/makeStyles';
import menuIcon from "../../../assets/icons/navMenuButtonIcon.svg";

const useStyles = makeStyles({
    Navbar: {
        height: 70,
        // width: "100%",
        position: "absolute",
        top: 0,
    },
    menuButton: {
        height: 70,
        width: 70,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: 0
    },
    menuButtonIcon: {

    }
})


const Navbar = props => {
    const classes = useStyles();

    return (
        <div className={classes.Navbar}>

            <div className={classes.menuButton}>
                <img src={menuIcon} className={classes.menuButtonIcon}/>
            </div>

        </div>
    )
}


export default Navbar;