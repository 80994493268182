import Logo from "./Logo";
import PropTypes from "prop-types";
import titleTextImg from "../../../assets/icons/SoftwareDevelopment/Title_Ucleate_White.png"

const LogoAndTitle = ({
    level = 'h4',
}) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Logo
                style={{
                    position: "relative",
                    // top: -7,
                    zIndex: 10
                }}
            />
            <div
                style={{
                    position: "relative",
                    left: -13,
                    top: 7,
                }}
            >
                <img
                    src={titleTextImg}
                    alt="Nucleate Software"
                    style={{
                        height: 26,
                    }}
                />
            </div>
        </div>
    )
}

LogoAndTitle.propTypes = {
    level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
}

export default LogoAndTitle;
