import emailjs from 'emailjs-com';
import makeStyles from '@mui/styles/makeStyles';
import React, {useContext, useState} from "react";
import TextField from "@mui/material/TextField";
import SubmitButton from "./SubmitButton";
// import Logo from "../Branding/Logo";
import logo from "../../../assets/bio/icons/logo-bio.png"
import {EnvironmentContext} from "../../App/App";

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh"
    },
    ContactBox: props => ({
        boxShadow: (props.innerWidth < 800) ?  null : "0 0 10px rgba(0, 0, 0, 0.3)",
        borderRadius: 10,
        justifyContent: "center",
        alignItems: "center",
        width: "min(80vw, 500px)",
        margin: "25px",
        padding: "25px",
        position: "relative",
    }),
    ContactH1: {
        display: "table",
        margin: "40px auto",
        color: "#695CC1",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "semi-bold",
        fontSize: 40,
        // letterSpacing: "10px",
    },
    Logo: {
        width: "min(80vw, 475px)",
        height: "10%",
        marginTop: 20,
        marginLeft: -10,
        marginBottom: -20,
    },
    ContactForm: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "40px auto",
    },
    ContactField: {
        margin: 20,
        width: "min(400px, 80vw)",
        color: "#695CC1",
        border: "red",
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#a098e0',
            },
        },
    },
});

const ContactPage = props => {
    const {innerWidth} = useContext(EnvironmentContext);
    const classes = useStyles({innerWidth});
    const [formData, setFormData] = useState({});

    const updateInput = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    };

    function sendEmail(e) {
        e.preventDefault();
        console.log(e.target)

    emailjs.sendForm('service_27u27rq', 'template_e0u40su', e.target, "user_9voB8HJ0uknwCgE56BENw")
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className={classes.root}>
            <div className={classes.ContactBox}>
                <img className={classes.Logo} src={logo} alt={"Nucleate Logo"}/>

                {/*<Logo style={{width: "min(450, 90vw)", marginLeft: -10, position: "relative"}}/>*/}
                {/*<h1 className={classes.ContactH1}>Get in touch</h1>*/}
                <form className={classes.ContactForm} onSubmit={sendEmail}>
                    <TextField
                        label="Name"
                        name="name"
                        value={formData.name || ""}
                        onChange={updateInput}
                        variant="outlined"
                        className={classes.ContactField}
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={formData.email || ""}
                        onChange={updateInput}
                        variant="outlined"
                        className={classes.ContactField}
                    />
                    <TextField
                        label="How can we help?"
                        name="message"
                        value={formData.message || ''}
                        onChange={updateInput}
                        multiline
                        className={classes.ContactField}
                        variant="outlined"
                        // style={{height: 150}}
                        rows={8}
                    />
                    <SubmitButton
                        type="submit"
                        style={{margin: 15}}
                        size={250}
                        background={"linear-gradient(92.72deg, #FF7A00 0.92%, #FFA800 88.24%)"}
                    />
                </form>
            </div>
        </div>
    );
};

export default ContactPage;