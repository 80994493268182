import makeStyles from "@mui/styles/makeStyles";
import BackdropGradientRectangle from "./BackdropGradientRectangle";

const useStyles = makeStyles({
    BannerBackdrop: props => ({
        position: "relative",
        overflow: "hidden",
        top: 0,
        width: "100%",
        height: props.height || "100%",
        // zIndex: 0,
        // opacity: 0.8,
        background: "linear-gradient(225deg, #6231EF 0%, #993FF8 85%)",
        // zIndex: 2,
    })
})

const BannerBackdrop = props => {
    const width = window.innerWidth;
    const height = window.innerHeight * 0.75;

    const classes = useStyles(props);

    return (
        <div className={classes.BannerBackdrop}>
            <BackdropGradientRectangle
                {...props}
                x={width * 0.85}
                width={width * 0.22}
                length={height * 1.33}
            />
            <BackdropGradientRectangle
                {...props}
                x={width * 0.75}
                width={width * 0.22}
                length={height}
            />
            <BackdropGradientRectangle
                {...props}
                x={width * 0.2}
                width={width * 0.22}
                length={height * 1.2}
            />
        </div>
    )
}

export default BannerBackdrop;
