import services from "./services";
import ServiceItemCard from "./ServiceItemCard";
import Section from "../../Section/Section";
import { Grid } from "@mui/material";


const OurServicesSection = () => (
    <Section title={"Our Services"}>
        <Grid
            container
            spacing={[4, 10]}
            alignItems="center"
            justifyContent="center"
            paddingY={5}
            paddingX={{ lg: 5, xl: 30 }}
        >
            {services.map(service => (
                <Grid item height="100%" sx={{ height: '100%' }}>
                    <ServiceItemCard key={service.title} {...service}/>
                </Grid>
            ))}
        </Grid>
    </Section>
)


export default OurServicesSection;
