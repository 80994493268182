import makeStyles from '@mui/styles/makeStyles';
import { useState } from "react";
import { wrap } from "popmotion";
import PropTypes from "prop-types";
import PrevIconButton from "./PrevIconButton";
import NextIconButton from "./NextIconButton";
import { AnimatePresence, motion } from "framer-motion";
import PageIndicatorDots from "./PageIndicatorDots";


const useStyles = makeStyles({
    PageSelectGallery: props => ({
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        ...(props.style)
    }),
    pageContainer: {
        width: "100%",
        padding: "5% 0",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
})


const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

const Carousel = props => {
    const [[page, direction], setPage] = useState([0, 0]);

    const numPages = Object.keys(props.pageData).length;
    const pageDataList = Object.values(props.pageData);

    const changePage = (newPage, direction = null) => {
        if (newPage === page) return 0;
        if (direction === null) {
            direction = newPage < page ? -1 : 1;
        }
        setPage([newPage, direction])
    }

    const pageIndex = wrap(0, numPages, page);

    const prev = () => changePage((page - 1) % numPages, -1);
    const next = () => changePage((page + 1) % numPages, 1);

    const classes = useStyles(props);

    const variants = {
        enter: (direction) => ({
            x: direction > 0 ? (window.innerWidth / 2) : -(window.innerWidth / 2),
            opacity: props.fade ? 0 : 1,
        }),
        center: {
            x: 0,
            opacity: 1
        },
        exit: (direction) => ({
            x: direction < 0 ? (window.innerWidth / 2) : -(window.innerWidth / 2),
            opacity: props.fade ? 0 : 1,
        })
    };

    return (
        <div className={classes.PageSelectGallery}>
            <PrevIconButton onClick={prev}/>

            <AnimatePresence custom={direction} exitBeforeEnter>
                <motion.div
                    className={classes.pageContainer}
                    key={pageIndex}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        duration: 0.2
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                            next();
                        } else if (swipe > swipeConfidenceThreshold) {
                            prev();
                        }
                    }}
                >

                    <props.pageComponent {...(pageDataList[pageIndex])} index={pageIndex}/>

                </motion.div>
            </AnimatePresence>


            <NextIconButton onClick={next}/>

            {props.pageIndicator && (
                <PageIndicatorDots
                    numPages={numPages}
                    current={pageIndex}
                    onChange={changePage}
                />
            )}
        </div>
    )
}

Carousel.propTypes = {
    pageData: PropTypes.object.isRequired,

    pageComponent: PropTypes.func.isRequired,
}

export default Carousel;
