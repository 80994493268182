import Modal from '@mui/material/Modal';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/Dialog";
import makeStyles from '@mui/styles/makeStyles';
import {useState, useEffect, useRef, useContext} from "react";
import {motion} from "framer-motion"
import ContactUsButton from "../../../../SoftwareDevelopment/HomePage/Buttons/ContactUsButton";
import {EnvironmentContext} from "../../../../App/App";
import imgSupport from "../../../../../assets/bio/support/support-dark.jpeg";


const useStyles = makeStyles((theme) => ({
    modalBody: {
        position: 'absolute',
        // width: 600,
        backgroundColor: "transparent", // theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        padding: 50,
        justifyContent: "center",
        background: "transparent",
        outline: "none",

    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: "relative",
        background: "transparent",
        padding: 50,
    },
    Heading: props => ({
        fontSize: props.innerWidth > 600 ? 36 : 2,
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "semi-bold",
        color: "#1E217C",
        marginBottom: 75,
    }),
}));

const bodyVariants = {
    open: {
        opacity: 1,
        transition: {delay: 0.6}
    },
    initial: {
        opacity: 0
    },
    exit: {
        opacity: 0
    }
};

const icon = {
    hidden: {
        pathLength: 0,
        fill: "rgba(255, 255, 255, 0)"
    },
    visible: {
        pathLength: 1,
        fill: "rgba(255, 255, 255, 1)"
    }
};

const ScienceModal = props => {
    const {innerWidth} = useContext(EnvironmentContext);
    const classes = useStyles({innerWidth});

    const Body = () => {
        const [height, setH] = useState(0);
        const [width, setW] = useState(0);
        const [path, setPath] = useState("");
        const ref = useRef();

        useEffect(() => {
            const elem = ref.current;
            const w = elem ? elem.clientWidth : 0;
            const h = elem ? elem.clientHeight : 0;
            const p =
            setW(w);
            setH(h);
            setPath(p)

        }, []);

        return props.science && (
            <>
                <motion.div
                    className={classes.modalBody}
                    variants={bodyVariants}
                    animate={"open"}
                    initial={"initial"}
                    exit={"exit"}
                    id={"modal"}
                    ref={ref}
                >
                    {/*<h2 id="simple-modal-title">{props.science.title}</h2>*/}
                    <h1 className={classes.Heading}>Details coming Soon</h1>
                    {
                        innerWidth > 600 ?
                            <ContactUsButton children="Get in touch" size={300}/> :
                            <ContactUsButton children="Get in touch" size={10}/>
                    }

                    <p id="simple-modal-description">
                        {/*Nucleate is a*/}
                    </p>
                </motion.div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    width={width + 50}
                    hieght={height + 50}
                >
                    <motion.path
                        d="
                            M91.7,100
                            H10.3A8.4,8.4,0,0,1,2,91.7
                            V10.3A8.4,8.4,0,0,1,10.3,2
                            H91.7a8.4,8.4,0,0,1,8.3,8.3
                            V91.7A8.4,8.4,0,0,1,91.7,100Z
                        "
                        transform="translate(-1 -1)"
                        // stroke-linejoin="round"
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{duration: 1}}
                        stroke={"white"}
                        strokeWidth={1}
                    />
                </svg>
            </>
        );
    }

    return (
        <Modal
            className={classes.modal}
            open={props.open}
            onClose={props.handleClose}
            // aria-labelledby="simple-modal-title"
            // aria-describedby="simple-modal-description"
        >
            <Body/>
        </Modal>
    );

};

export default ScienceModal;