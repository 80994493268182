import React from "react";
import { Button } from "@mui/material";


const ContactUsButton = props => {
    return (
        <Button
            sx={{
                ...(props.style),
            }}
            variant="contained"
            href="/#contact-us"
            {...props}
        >
            {props.children || "Contact us"}
        </Button>
    )
};


export default ContactUsButton;
