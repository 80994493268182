import React, { useState } from "react";
import { Button, CircularProgress, FormGroup, Grid, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { Close } from "@mui/icons-material";
import { validateEmail } from "../../../BigBoyFunctions";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "../../../firebase";

const contactFormItems = {
    name: {
        label: 'Name',
        validator: (value) => value.length > 0,
        errorText: 'Please enter your name',
    },
    email: {
        label: 'Email',
        validator: validateEmail,
        errorText: 'Please enter a valid email address',
    },
    message: {
        label: 'Message',
        validator: (value) => value.length > 20,
        errorText: 'Please enter a message longer than 20 characters',
        inputProps: {
            multiline: true,
            rows: 8,
        }
    }
}

const ContactForm = () => {
    // Initialize form values to empty strings
    const [formValues, setFormValues] = useState(
        Object.fromEntries(
            Object.keys(contactFormItems).map(
                itemName => [itemName, ""]
            )
        )
    )
    // null, pending, or success (for snackbar & button enabled/disabled)
    const [submitStatus, setSubmitStatus] = useState(null);
    // array of error messages
    const [formErrors, setFormErrors] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const validateForm = () => {
        const errorMessages = Object.entries(formValues).map(([name, value]) => (
            contactFormItems[name].validator(value) ? false : contactFormItems[name].errorText
        )).filter((message) => message !== false);
        setFormErrors(errorMessages);
        return errorMessages.length === 0;
    }

    const updateFormValue = (name) => (event) => {
        setFormErrors([]);
        const value = event.target.value;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    }

    const send = async () => {
        /// get the contactViaEmail function from firebase
        const functions = getFunctions();
        const contactViaEmail = httpsCallable(functions, 'contactViaEmail');
        /// Make sure form is valid before calling
        if (!validateForm()) {
            return;
        }

        /// Add message to database
        addDoc(collection(db, "contact"), {
            ...formValues,
            date: Timestamp.fromDate(new Date()),
            domain: "software-development",
        }).catch((error) => {
            console.error("Error adding document: ", error);
        });

        /// Set status, call function, and set status to that returned
        setSubmitStatus("pending");
        const emailResult = await contactViaEmail(formValues)
        const data = emailResult.data
        const variant = data.success ? "success" : "error";
        setSubmitStatus(variant);

        /// Show snackbar indicating success or failure
        enqueueSnackbar(
            data.success
                ? 'Message sent! We\'ll be in touch shortly.'
                : 'Oops, something went wrong, please try again.',
            {
                variant,
                preventDuplicate: true,
                action: (key) => (
                    <IconButton color="white" onClick={() => closeSnackbar(key)}>
                        <Close color="inherit"/>
                    </IconButton>
                )
            },
        );
    }


    return (
        <FormGroup>
            {Object.entries(contactFormItems).map(([name, item]) => {
                const errorMessage = formErrors.includes(contactFormItems[name].errorText)
                    ? contactFormItems[name].errorText
                    : null;
                return (
                    <TextField
                        key={name}
                        variant="outlined"
                        error={errorMessage !== null}
                        size="small"
                        placeholder={item.label}
                        helperText={errorMessage}
                        onChange={updateFormValue(name)}
                        {...(item?.inputProps ?? {})}
                    />
                )
            })}
            <Button
                variant="contained"
                onClick={send}
                sx={{
                    color: "white"
                }}
                disabled={submitStatus === "success" || formErrors.length > 0}
            >
                {submitStatus === "pending" && (
                    <CircularProgress size={25} color="inherit" />
                )}
                {submitStatus !== "pending" && submitStatus !== "success" && "Send"}
                {submitStatus === "success" && "Sent!"}
            </Button>
        </FormGroup>
    )
}


export default ContactForm;
