import HomePage from "./HomePage/HomePage";

const DataScience = props => {
    return (
        <div>
            <HomePage/>
        </div>
    )
}

export default DataScience;