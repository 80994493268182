import makeStyles from "@mui/styles/makeStyles";
import BannerBackdrop from "./BannerBackdrop/BannerBackdrop";
import welcomeArt from "../../../../assets/software/clipArt/welcomeArt.svg"
import LearnMoreButton from "../Buttons/LearnMoreButton";
import ContactUsButton from "../Buttons/ContactUsButton";
import {useContext} from "react";
import {EnvironmentContext} from "../../../App/App";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

const useStyles = makeStyles({
    WelcomeBanner: {
        height: "100vh",
        width: "100vw",
    },
    topSpacer: props => ({
        height: 100,
        width: "70%"
    }),
    bannerContent: {
        // background: "black"
        padding: 20,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        zIndex: 10
    },
    welcomeArt: props => ({
        zIndex: 5,
        background: "transparent",
        height: props.percent(50),
        // width: "50vw"
    }),
    header: props => ({
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: 200,
        lineHeight: "140%",
        textAlign: "center",
        color: "#FFFFFF",
        marginBottom: 0,
    }),
    subHeader: props => ({
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "140%",
        textAlign: "center",
        color: "#FFFFFF",
        marginTop: 0,
        marginBottom: props.percent(5),
    }),
    buttonRow: props => ({
        marginBottom: props.percent(5),
    })
})

const WelcomeBanner = props => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { scaleMinWinDim } = useContext(EnvironmentContext);

    const classes = useStyles({percent: scaleMinWinDim});

    return (
        <section className={classes.WelcomeBanner}>
            <div className={classes.bannerContent}>
                <div className={classes.topSpacer}/>

                <Typography variant="h3" className={classes.header}>
                    Empower your business
                </Typography>
                <Typography variant="h3" className={classes.subHeader}>
                    with tailored apps and technology
                </Typography>

                <Grid container spacing={4} justifyContent="center" className={classes.buttonRow}>
                    <Grid item sm={7} md={4} lg={3}>
                        <LearnMoreButton size={isSmall ? "medium" : "large"} sx={{ width: '100%' }}/>
                    </Grid>
                    <Grid item sm={7} md={4} lg={3}>
                        <ContactUsButton size={isSmall ? "medium" : "large"} sx={{ width: '100%' }}/>
                    </Grid>
                </Grid>

                <img className={classes.welcomeArt} src={welcomeArt} alt={"Welcome"}/>
            </div>
            <BannerBackdrop height={"75%"}/>
        </section>
    )
}


export default WelcomeBanner;
