import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    ContactPage: {
        height: "100vh",
        width: "100vw",
        background: "green"
    }
})

const ContactPage = props => {
    const classes = useStyles();
    return (
        <div className={classes.ContactPage}>
            <h1>HIdsffhadfhsadfhdsd</h1>
        </div>
    )
}

export default ContactPage;
