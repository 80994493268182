import makeStyles from "@mui/styles/makeStyles";
import WelcomeBanner from "./WelcomeBanner/WelcomeBanner";
import OurServicesSection from "./OurServiceSection/OurServicesSection";
import BannerSection from "./BannerSection/BannerSection";
// import OurCasesSection from "./OurCasesSection/OurCasesSection";
import ContactSection from "../Contact/ContactSection";
import OurProcessSection from "./OurProcessSection/OurProcessSection";

const useStyles = makeStyles({
    HomePage: {
        overflow: "hidden",
    }
})

const HomePage = props => {
    const classes = useStyles();
    return (
        <div className={classes.HomePage}>
            <WelcomeBanner/>
            <OurServicesSection/>
            <OurProcessSection/>
            <BannerSection/>
            {/*<OurCasesSection/>*/}
            <ContactSection/>
        </div>
    )
}

export default HomePage;
