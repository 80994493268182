export const processItems = [
    {
        title: "Consultation",
        summary: "Tell us about your vision",
    },
    {
        title: "Design & Planning",
        summary: "We'll help you craft the perfect product plan",
    },
    {
        title: "Development",
        summary: "We'll build your product",
    },
    {
        title: "Testing",
        summary: "We'll make sure your product is ready for the world",
    },
    {
        title: "Go Live!",
        summary: "We'll launch your product",
    },
    {
        title: "Growth & Marketing",
        summary: "We'll help you grow your product",
    },
    {
        title: "Analyze & Improve",
        summary: "We'll help improve your app using analytics data & feedback",
    },
]
