import { Phone, Mail } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";

const contactInfoItems = {
    email: { value: "contact@nucleate.io", Icon: Mail },
    phone: { value: "720-425-5926", Icon: Phone },
}

const useStyles = makeStyles({
    ContactInfo: props => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& svg": {
            margin: 10,
            color: "#FFA701",
        },
        "& > div": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
        }
    }),
    infoItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 10,
    }
})


const ContactInfo = () => {
    const classes = useStyles();

    return (
        <div className={classes.ContactInfo}>
            <div>
                {Object.values(contactInfoItems).map((item, index) => {
                    const { Icon, value } = item;
                    return (
                        <span key={value} className={classes.infoItem}>
                        {Icon && <Icon/>}
                            {value}
                    </span>
                    )
                })}
            </div>
        </div>
    )
}

export default ContactInfo;
