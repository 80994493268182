import makeStyles from '@mui/styles/makeStyles';
import SectionTitle from "../../TextComponents/SectionTitle";
import Grid from "./Grid";
import services from "./services";
import uuid from "react-uuid"
import ScienceCard from "./ScienceCard/ScienceCard";
import React from "react";

const useStyles = makeStyles({
    SciencesSection: props => ({
        marginTop: "5vh",
        paddingBottom: "3vh",
        zIndex: 1,
        background: "rgba(255, 255, 255, 0.6)",
        padding: 5,
    }),
    card: {
      display: "flex",
      width: "80vw",
      justifyContent: "center",
      margin: 30,
        zIndex: 2,
    }
});

const SciencesSection = props => {
    const classes = useStyles(props);
    // const arrLength = services.length;
    // const refArr = useRef([]);
    // useEffect(() => {
    //     if (refArr.current.length !== arrLength) {
    //         refArr.current = Array(arrLength).fill().map(
    //             (_, i) => refArr.current[i] || createRef()
    //         );
    //     }
    // });


    // useEffect(() => {
    //     const detectWrap = function(className) {
    //         let wrappedItems = [];
    //         let prevItem = {};
    //         let currItem = {};
    //         // TODO: use array of refs instead of document
    //         const items = refArr.current;
    //
    //         for (var i = 0; i < items.length; i++) {
    //             currItem = items[i].getBoundingClientRect();
    //             if (prevItem && prevItem.top < currItem.top) {
    //                 wrappedItems.push(items[i]);
    //             }
    //             prevItem = currItem;
    //         }
    //
    //     return wrappedItems;
    //
    //   };

    //   const handleWrapChange = (event) =>{
    //     let wrappedItems = detectWrap('item');
    //     for (let k = 0; k < wrappedItems.length; k++) {
    //       wrappedItems[k].className = "wrapped";
    //     }
    //   };
    //
    //   // window.addEventListener("load", handleWrapChange)
    //   window.addEventListener("resize", handleWrapChange);
    //
    //   return () => {
    //     // window.removeEventListener("load", handleWrapChange);
    //     window.removeEventListener("resize", handleWrapChange)
    //   }
    // }, [])

    return (
        <div className={classes.SciencesSection}>
            <SectionTitle>Scientific Domains</SectionTitle>

            <Grid style={{width: "90vw", margin: "auto"}}>
                {services.map((science, index) => (
                    <ScienceCard onClick={props.getModalLauncher(science)} className={classes.card} key={uuid()} index={index} {...science}/>
                ))}
            </Grid>
        </div>
    )
}

export default React.memo(
    SciencesSection,
    (prev, next) => true
);