import logo from "../../../assets/bio/icons/logo-bio.png"
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    Logo: props => ({

        ...(props.style)
    }),
})

const Logo = props => {
    const classes = useStyles(props);

    return (
        <img className={classes.Logo} src={logo} alt={"Nucleate Logo"}/>
    )
}

export default Logo;
