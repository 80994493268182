import imgComputerVision from "../../../../assets/bio/careers/computer-vision.jpeg"
import imgGenetics from "../../../../assets/bio/careers/genetics.jpeg"
import imgML from "../../../../assets/bio/careers/machine-learning.jpeg"
import imgOncology from "../../../../assets/bio/careers/oncology-inflammation.jpeg"
import imgSingleCell from "../../../../assets/bio/careers/single-cell.jpeg"
import imgStructural from "../../../../assets/bio/careers/structural.jpeg"
import logo from "../../../../assets/bio/icons/logo192padded.png"

const careerImages = {
    "computer-vision": imgComputerVision,
    "genetics": imgGenetics,
    "machine-learning": imgML,
    "oncology-inflammation": imgOncology,
    "single-cell": imgSingleCell,
    "structural": imgStructural,
    "default": logo,
};

export default careerImages;