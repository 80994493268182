import makeStyles from "@mui/styles/makeStyles";
import BackdropGradientRectangle from "../HomePage/WelcomeBanner/BannerBackdrop/BackdropGradientRectangle";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

const useStyles = makeStyles({
    FooterBackdrop: props =>  ({
        position: "absolute",
        overflow: "hidden",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        background: "linear-gradient(-90deg, #6231EF 0%, #993FF8 85%)",
    }),
})


const FooterBackdrop = (props) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const classes = useStyles(props);

    return (
        <div className={classes.FooterBackdrop}>
            {isDesktop && (
                <>
                    <BackdropGradientRectangle
                        angle={-135}
                        x={100}
                        y={-10}
                        width={200}
                        length={350}
                        minOpacity={0.4}
                        gradientAngle={60}
                    />
                    <BackdropGradientRectangle
                        angle={-135}
                        x={400}
                        y={-10}
                        width={260}
                        length={350}
                        maxOpacity={0.2}
                        minOpacity={0.4}
                        gradientAngle={60}
                    />
                </>
            )}
        </div>
    )
}

export default FooterBackdrop;
