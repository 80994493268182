import makeStyles from '@mui/styles/makeStyles';
import {useContext} from "react";
import {EnvironmentContext} from "../../../../App/App";
import uuid from "react-uuid";
import React from "react";
import ScienceCardDescriptor from "./ScienceCardDescriptor";
import ScienceCardVid from "./ScienceCardVid";
import Modal from '@mui/material/Modal';

import ingestIcon from "../../../../../assets/bio/services/ingestion.svg";
import aiIcon from "../../../../../assets/bio/services/ai.svg";
import exploreIcon from "../../../../../assets/bio/services/explore.svg";
import IconContainer from "./IconContainer";


// const {scaleMinWinDim} = useContext(EnvironmentContext);


const useStyles = makeStyles(theme => ({
    DescriptionCards: props => ({
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexDirection: props.innerWidth < 1300 ? "column": "row",
        width: props.innerWidth > 1800 ? 1500 : props.innerWidth > 600 ? "95%" : "97.5%",
        margin: 0,
        paddingLeft: 50,
        paddingRight: 50,
        marginTop: -100,
        marginBottom: 100,
    })
}));

const DescriptionCards = props => {
    const {scaleMinWinDim, innerWidth} = useContext(EnvironmentContext);
    const classes = useStyles({percent: scaleMinWinDim, innerWidth});

    return (
        <div id="learn-more" className={classes.DescriptionCards}>
            <IconContainer
                icon={ingestIcon}
                title={"Automated Processing"}
                body={"Alignments, QC, phenotyping, variant calling, differential expression, computer vision, you name it. You won't even have to push a button."}
                onClick={props.getModalLauncher("ingest")}
            />
            <IconContainer
                icon={aiIcon}
                title={"Expert Analysis"}
                body={"Specialized analyses from our world class bioinformatics team on our collaborative cloud platform. End-to-end analyses or integrated with your computational team."}
                onClick={props.getModalLauncher("ai")}
            />
            <IconContainer
                icon={exploreIcon}
                title={"Bio Dashboards"}
                body={"Go beyond sequences and differential expression. Our interactive dashboards illuminate your biology with pathways and networks, incorporating valuable public data like GTEx, ..."}
                onClick={() => console.log("clicked")}
            />
        </div>
    )
};

export default DescriptionCards;