import makeStyles from '@mui/styles/makeStyles';
import {useContext} from "react";
import {EnvironmentContext} from "../../App/App";
import React from "react";
import CareerCard from "./CareersCard/CareerCard";
import careers from "./careers.json"


const useStyles = makeStyles(theme => ({
    CareerCardSection: props => ({
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexDirection: "column",
        width: props.innerWidth > 1500 ? 1500 : props.innerWidth > 600 ? "90%" : "97.5%",
        margin: 200,
        paddingLeft: 50,
        paddingRight: 50,
        // marginTop: 100,
        // marginBottom: 100,
    })
}));

const CareerCardSection = props => {
    const {scaleMinWinDim, innerWidth} = useContext(EnvironmentContext);
    const classes = useStyles({percent: scaleMinWinDim, innerWidth});

    return (
        <div id="learn-more" className={classes.CareerCardSection}>
            {careers.map(careerData => (
                <CareerCard {...careerData}/>
            ))}
        </div>
    )
};

export default CareerCardSection;