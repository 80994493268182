import {
    createTheme,
    responsiveFontSizes,
    ThemeProvider,
    StyledEngineProvider,
} from "@mui/material/styles";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import ContactPage from "./ContactPage/ContactPage";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import { SnackbarProvider } from "notistack";

const theme = responsiveFontSizes(createTheme({
    palette: {
        primary: {
            light: "#853BF6",
            main: "#7C38F4",
            contrastText: "#fff",
        },
        secondary: {
            main: "#FFA701",
        },
        text: {
            primary: "#000",
            secondary: "#fff",
        },
        success: {
            main: "#2e7d32",
            light: "#4caf50",
            dark: "#1b5e20",
        }
    },
    typography: {
        fontFamily: [
            '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            'sans-serif'
        ].join(','),
        body1: {

        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 100,
                    color: "white",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                },
                outlined: {
                    background: "transparent",
                    border: "2px solid white",
                    fontWeight: "normal",
                },
                contained: {
                    background: "linear-gradient(92.72deg, #FF7A00 0.92%, #FFA800 88.24%)",
                    fontWeight: "bold",
                },
                sizeLarge: {
                    padding: "15px 30px",
                    fontSize: 24,
                },
                sizeMedium: {
                    padding: "8px 40px",
                    fontSize: 18,
                },
                sizeSmall: {
                    padding: "5px 20px",
                    fontSize: 16,
                }
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: '#585858',
                    margin: "8px 0",
                    padding: 0,
                    borderRadius: 0,
                },
                outlined: {
                    borderRadius: 0,
                    border: "none",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: "#FAF9FE",
                    padding: 0,
                    "& fieldset:not(:focus)": {
                        border: 'none'
                    },
                },
            },
            defaultProps: {
                sx: {

                    "&.Mui-error": {
                        "& fieldset": {
                            border: '1px solid red'
                        },
                    },
                    borderRadius: 0,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.09)",
                    borderRadius: "12px",
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: 35,
                }
            },
        }
    },
}));

const SoftwareDevelopment = props => {
    const {path, url} = useRouteMatch();


    return (
        <div>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        maxSnack={1}
                        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                        autoHideDuration={6000}
                    >
                        <Navbar/>
                        <Switch>
                            <Route exact path={path} component={HomePage}/>
                            <Route exact path={`${url}/contact`} component={ContactPage}/>
                        </Switch>
                        <Footer/>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    );
}


export default SoftwareDevelopment;
