import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    Grid: props => ({
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",

        width: props.width || "100%",
        padding: props.padding || 0,

        ...(props.style)
    }),
})

const Grid = props => {
    const classes = useStyles(props);
    return (
        <div className={classes.Grid}>
            {props.children}
        </div>
    )
}

export default Grid;