import makeStyles from '@mui/styles/makeStyles';
import {Button} from "@mui/material";
import {scaleCssSize, omit} from "../../../../BigBoyFunctions";
import React, {useContext} from "react";
import {EnvironmentContext} from "../../../App/App";
import { Link } from "react-scroll";

const useStyles = makeStyles({
    ContactUsButton: props => {
        const width = props.width || props.size || props.percent(25);
        const height =  props.height || props.size / 5 || props.percent(6);

        return ({
            background: props.background || "linear-gradient(92.72deg, #FF7A00 0.92%, #FFA800 88.24%)",
            color: props.color || "white",
            width,
            height,
            borderRadius: scaleCssSize(height, 0.5),
            border: props.border || "0px solid white",
            padding: 0,
            // margin: props.margin || 20,

            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: scaleCssSize(height, 0.35),
            lineHeight: "86%",
            transitionDuration: "0.1s",
            overflow: "hidden",

            ...(props.style),
            "&:hover": {
                border: "4px solid white",
            }


        })
    },
    bgWrapper: props => {
        const width = props.width || props.size || props.percent(25);
        const height =  props.height || props.size / 5 || props.percent(6);
        return ({
            height,
            width,
            background: "rgba(255, 168, 0, 0)",
            padding: 0,
            transitionDuration: '0.4s',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
                background: "rgba(255, 168, 0, 1)",
            }
        })
    }

})



const ContactUsButton = props => {
    const {scaleMinWinDim} = useContext(EnvironmentContext);

    const classes = useStyles({...props, percent: scaleMinWinDim});

    return (
        <Button
            className={classes.ContactUsButton}
            {...omit(props, ["size", "height", "width"])}
            component={Link}
            to={"/contact"}
        >
            <div className={classes.bgWrapper}>

                {props.children || "Contact us"}
            </div>
        </Button>
    )
}


export default ContactUsButton;
