import _ from "lodash"

export const cssSizeToPX = (cssSize) => {
    if (typeof cssSize === 'number') return cssSize;

    let size = splitCssSize(cssSize)[0];

    if (cssSize.includes("vw")) {
        size = (size * window.innerWidth) / 100;
    }
    else if (cssSize.includes("vh")) {
        size = (size * window.innerHeight) / 100;
    }
    return size;
}

export const scaleCssSize = (cssSizeString, proportion) => {
    if (typeof cssSizeString === 'number') {
        cssSizeString = cssSizeString.toString() + "px";
    }
    let [size, unit] = splitCssSize(cssSizeString);
    size *= proportion
    size = size.toString() + unit;
    return size;
}

export const splitCssSize = (cssSizeString) => {
    if (typeof cssSizeString === 'number') return [cssSizeString, 'px']
    let size = "";
    let unit = "";
    for (let char of cssSizeString) {
        if (!isNaN(char) || char === '.' || char === '-') {
            size += char;
        }
        else {
            unit += char;
        }
    }
    size = parseFloat(size);
    return [size, unit];
}


export const percentageOfWindowWidth = px => (100 * px) / window.innerWidth;

export const percentageOfWindowHeight = px => (100 * px) / window.innerHeight;

export const percentageOfWindow = (px, dimension = "width") => {
    if (["w", "width", "x"].includes(dimension.toLowerCase())) {
        return percentageOfWindowWidth(px);
    }
    if (["h", "height", "y"].includes(dimension.toLowerCase())) {
        return percentageOfWindowHeight(px);
    }
}

export const pxToVw = (px) => percentageOfWindowWidth(px).toString() + "vw";

export const pxToVh = (px) => percentageOfWindowHeight(px).toString() + "vh";

export const minimumWindowDimension = () => (window.innerWidth <= window.innerHeight) ? "vw" : "vh";


export const minWinDimPercent = percent => percent.toString() + minimumWindowDimension();


export const maxWinDimPercent = percent => percent.toString() + minimumWindowDimension();

export const minWinDimPx = () =>
    (window.innerWidth <= window.innerHeight) ? window.innerWidth : window.innerHeight;

export const minWindowDimPx = minWinDimPx; //() =>
    // (window.innerWidth <= window.innerHeight) ? window.innerWidth : window.innerHeight;

export const omit = (object, keys) =>  _.omit(object, keys)


export const kebabify = (string) => (
    string.toLowerCase().replace(' ', '-')
)

export const validateEmail = (email) => {
    const emailRegex = new RegExp(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
    );
    const match = email.match(emailRegex)?.[0];
    return match === email;
}
