import './App.css';
import {Route, Switch, useLocation, useHist} from "react-router-dom";
import Bio from "../Bio/Bio"
import DataScience from "../DataScience/DataScience";
import SoftwareDevelopment from "../SoftwareDevelopment/SoftwareDevelopment";
import React, {useEffect, useState} from "react";
import {minWinDimPx} from "../../BigBoyFunctions";
import { CssBaseline } from "@mui/material";

export const EnvironmentContext = React.createContext(null)


function App() {
    const [minWinDim, setMinWinDim] = useState(minWinDimPx());
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    const location = useLocation();

    useEffect(() => {
        const updateMinWinDim = (e) => {
            setInnerWidth(window.innerWidth);
            const minDim = minWinDimPx();
            if (minDim !== minWinDim)
                setMinWinDim(minDim)
        }
        window.addEventListener("resize", updateMinWinDim);

        return () => window.removeEventListener("resize", updateMinWinDim);
    }, [])

    useEffect(() => {
        console.log("minWinDim: ", minWinDim)
    }, [minWinDim]);

    const scaleMinWinDim = (percent) => minWinDim * (percent / 100);

    return (
        <div className="App">
            <CssBaseline/>
            <EnvironmentContext.Provider
                value={{
                    innerWidth,
                    minWinDim,
                    scaleMinWinDim,
                    location
                }}
            >
                <Switch>
                    <Route path={"/"} component={SoftwareDevelopment}/>
                    <Route path={"/datascience"} component={DataScience}/>
                    <Route path={"/bio"} component={Bio}/>
                </Switch>
            </EnvironmentContext.Provider>
        </div>
    );
}

export default App;
