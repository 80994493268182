import makeStyles from  "@mui/styles/makeStyles";
import BannerBackdrop from "../WelcomeBanner/BannerBackdrop/BannerBackdrop";
import ContactUsButton from "../Buttons/ContactUsButton";
import phoneClipArt from "../../../../assets/software/clipArt/phoneAppClipArt.png";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";


const useStyles = makeStyles({
    BannerSection: {
        position: "relative",
        width: "100%",
        color: "white",
        overflow: "visible",
    },
    background: {
        height: "100%",
        width: "100%",
        position: "absolute",
    },
    content: {
        width: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        overflow: "visible",
        padding: 50,
    },
    leftSide: {
        height: "100%",
        width: "40%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    rightSide: {
        width: "60%",
        padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        textAlign: "left",
    },
    headline: {
        padding: 0,
        margin: 0,
        fontFamily: "Gotham Pro",
        fontStyle: "normal",
        fontWeight: "bold",
    },
    subtext: {
        marginBottom: 40,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
    }
})

const BannerSection = props => {
    const {height = 300} = props;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles({props, height});

    return (
        <div className={classes.BannerSection}>
            <div className={classes.background}>
                <BannerBackdrop y={-400}/>
            </div>
            <div className={classes.content}>
                <Grid container spacing={8} justifyContent="center" alignItems="center" >
                    <Grid item md={4}>
                        <img width="100%" src={phoneClipArt} alt={"Phone app clip art"}/>
                    </Grid>
                    <Grid item md={8} sx={{textAlign: 'left'}} justifyContent="flex-start" alignItems="flex-start">
                        <Typography variant="h4"  className={classes.headline}>Just need some advice?</Typography>
                        <Typography variant="h6" className={classes.subtext}>If we’re not the best solution, we’ll tell you who is</Typography>
                        <ContactUsButton size={isSmall ? "small" : "medium"} style={isSmall ? { width: "100%" } : {}}  />
                    </Grid>
                </Grid>
            </div>


        </div>
    )
}

export default BannerSection;
