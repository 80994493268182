import {motion, useTransform, useViewportScroll} from "framer-motion";
import makeStyles from '@mui/styles/makeStyles';
import NucleateTitle from "../Branding/NucleateTitle";
import Logo from "../Branding/Logo";
import ContactUsButton from "../HomePage/Buttons/ContactUsButton";
import {Link} from "react-router-dom";
import React, {useContext} from "react";
import {EnvironmentContext} from "../../App/App";
import AnchorLink from "react-anchor-link-smooth-scroll";
// import {useContext, useEffect} from "react";
// import {EnvironmentContext} from "../../App/App";

const sectionStyleBase = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // border: "2px solid orange"
}


const useStyles = makeStyles({
    Navbar: props => ({
        // border: "2px solid red",
        width: "100vw",
        height: 70,
        position: "fixed",
        zIndex: 1000,
        overflow: "hidden",
        boxSizing: "border-box",
        padding: 0,
    }),
    background: {
        background: "white",
        width: "100%",
        height: "100%",
        margin: 0,
        position: "absolute"
    },
    content: {
        position: "absolute",
        padding: "0px 30px",
        width: "100vw",
        height: "100%",
        margin: 0,
        display: "flex",
        boxSizing: "border-box",
        justifyContent: "space-between"
    },
    titleSection: {
        ...sectionStyleBase,
        width: 200,
    },
    middleSection: {
        ...sectionStyleBase,
        width: "80%",
    },
    rightSection: {
        ...sectionStyleBase,
        display: "flex",
        justifyContent: "flex-end",
        width: 250,
    },
    NavLink: {
        textDecoration: "none",
        margin: "0 50px"
    },
    navLinkText: {
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "167.19%",
        color: "#8F7EDB",
    }
});

const NavLink = props => {
    const classes = useStyles();

    return (
            <>
                {props.scroll ?
                    <AnchorLink className={classes.NavLink} href={props.to} style={{textDecoration: "none"}} offset="75">
                        <h3 className={classes.navLinkText}>
                            {props.children}
                        </h3>
                    </AnchorLink> :
                    <Link className={classes.NavLink} to={props.to}>
                        <h3 className={classes.navLinkText}>
                            {props.children}
                        </h3>
                    </Link>
                }
            </>
        )
};

const Navbar = props => {
    const {scrollY} = useViewportScroll();
    const opacity = useTransform(scrollY, [0, 200, 500], [0, 1, 1]);
    const {innerWidth} = useContext(EnvironmentContext);
    const classes = useStyles({...props, opacity, innerWidth});

    return (
        <motion.div className={classes.Navbar}>
            <motion.div className={classes.background} animate={{opacity}}/>
            <div className={classes.content}>
                <div className={classes.titleSection}>
                    <Logo style={{marginRight: 10, height: "80%", align: "left"}}/>
                </div>
                {innerWidth > 600 ?
                    <div className={classes.middleSection}>
                            <NavLink scroll={true} to={"#learn-more"}>About</NavLink>
                        {/*<NavLink to={"/bio"}>Process</NavLink>*/}
                        <NavLink to={"/careers"}>Careers</NavLink>
                    </div> : null
                }
                {
                    innerWidth > 500 ?
                        <div className={classes.rightSection}>

                        <ContactUsButton
                            style={{
                                background: "linear-gradient(92.72deg, #FF7A00 0.92%, #FFA800 88.24%)",
                            }}
                            width={150}
                            height={40}
                        />
                        </div>
                        :
                        null
                }
            </div>

        </motion.div>
    )
}


export default Navbar