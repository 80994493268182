import React from "react";
import Section from "../../Section/Section";
import { Card, Grid, Typography } from "@mui/material";
import ourProcessGraphic from "../../../../assets/software/clipArt/processArt.svg";
import { processItems } from "./processItems";
import dottedLine from "../../../../assets/software/dottedVerticalOrangeConnectorLine.png";


const OurProcessSection = () => {
    return (
        <Section title="Our Process" gray>
            <Grid container rowGap={3} spacing={{ md: 6, sm: 4 }} paddingX={4}>
                <Grid item xs={12} md={6} lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={ourProcessGraphic} width="100%" alt="our process graphic"/>
                </Grid>
                <Grid item xs={12} md={6} lg={6} justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                    {processItems.map((item, index) => (
                        <React.Fragment key={item.title}>
                            <Card
                                size="medium"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    padding: '10px 13px',
                                    width: "100%",
                                    borderRadius: 100,
                                    minHeight: 60,
                                }}
                            >
                                <Typography variant="h3" fontWeight="bold" color="primary" paddingRight={2}>
                                    0{index + 1}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item.summary}
                                </Typography>
                            </Card>
                            {index === processItems.length - 1 ? null : (
                                <img
                                    src={dottedLine}
                                    height={20}
                                    style={{
                                        margin: '3px 20px',
                                    }}
                                    alt="connecting line between process items"
                                />
                            )}
                        </React.Fragment>
                    ))}
                </Grid>
            </Grid>
        </Section>
    )
}

export default OurProcessSection;
