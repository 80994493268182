import makeStyles from '@mui/styles/makeStyles';
import Navbar from "../Navbar/Navbar";
import React, {useState} from "react";
import CareerCardSection from "./CareerCardSection";



const useStyles = makeStyles({
    HomePage: {
        // overflow: "hidden",
        // boxSizing: "border-box",
        alignItems: "center",
        // justifyContent: "center",
        display: "flex",
        flexDirection: "column",
    },
    background: {
        width: "100%",
        backgroundSize: "auto",
        backgroundRepeat: "repeat-y",
        zIndex: 0,
    },
});

const CareersPage = props => {
    const classes = useStyles();
    const [modalScience, setModalScience] = useState({isOpen: false});

    const getModalLauncher = science => () => {
        console.log("launching modal");
        setModalScience({science: science, isOpen: true});
    };

    const closeModal = () => {
        console.log("closing modal");
        setModalScience({isOpen: false});
    };

    return (
        <div className={classes.HomePage}>
            <Navbar/>
            <CareerCardSection/>
        </div>
    )
};

export default CareersPage;