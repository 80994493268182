import SectionTitle from "../UI/Text/SectionTitle";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { kebabify } from "../../../BigBoyFunctions";

const useStyles = makeStyles({
    Section: props => ({
        width: "100vw",
        maxWidth: "100%",
        // height: "90vh",
        position: "relative",
        padding: props?.padding ?? "60px 0",
        background: props.background || "white",
        boxSizing: "border-box",
    }),
    titleRow: {
        height: "10%",
        margin: 0,
        marginTop: 45,
        boxSizing: "border-box",
    },
    contentSection: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "90%",
        margin: 0,
        boxSizing: "border-box",
    },
})

const Section = (props) => {

    const classes = useStyles({
        ...props,
        background: props?.gray ? '#FAF9FE' : props?.background
    });

    return (
        <section
            className={classes.Section}
            id={props.title ? kebabify(props.title) : null}
            {...props}
        >
            {props?.title && (
                <div className={classes.titleRow}>
                    <SectionTitle>{props.title}</SectionTitle>
                </div>
            )}

            {props.children}
        </section>
    )
}

Section.propTypes = {
    title: PropTypes.string,
    background: PropTypes.string,
    gray: PropTypes.bool,
}

export default Section;
