import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash"

const useStyles = makeStyles({
    PageIndicator: props => ({
        display: "flex",
        justifyContent: "center",
    }),
    PageDot: props => ({
        borderRadius: "50%",
        marginLeft: props.size / 4,
        marginRight: props.size / 4,
        height: props.size,
        width: props.size,
        overflow: "visible",
        background: "rgba(238, 238, 238, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    }),
    indicator: {

    }
})

const variants = {
    enter: {
        scale: 0,
        opacity: 0,
    },
    exit: {
        scale: 0,
        opacity: 0,
    },
    visible: {
        scale: 1,
        opacity: 1,
    }
}

const PageIndicatorDots = props => {
    const {size = 18, current} = props;
    const classes = useStyles({...props, size});

    const indexArray = _.range(props.numPages)

    return (
        <motion.div className={classes.PageIndicator}>
            {indexArray.map((index) => (
                <motion.div
                    className={classes.PageDot}
                    key={`indicator-${index}`}
                    onClick={() => props.onChange(index)}
                >
                    <AnimatePresence initial exitBeforeEnter={true}>
                        {index === current && (
                            <motion.div
                                key={`indicator-${index}`}
                                style={{
                                    minWidth: "110%",
                                    minHeight: "110%",
                                    borderRadius: "50%",
                                    border: "3px solid rgba(124, 56, 244, 1)",
                                    boxSizing: "border-box",
                                }}
                                variants={variants}
                                initial="enter"
                                animate="visible"
                                exit="exit"

                                transition={{
                                    // type: "spring",
                                    duration: 0.5,
                                    // stiffness: 30,
                                    ease: "easeInOut",
                                }}
                            />
                        )}
                    </AnimatePresence>
                </motion.div>
            ))}
        </motion.div>
    )
}

PageIndicatorDots.propTypes = {
    numPages: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    onChange: PropTypes.func
}

export default PageIndicatorDots;
