import multiOmicsVid from "../../../../assets/bio/videos/multi-omics.mp4";
import singleCellVid from "../../../../assets/bio/videos/single-cell.mp4";
import drugDiscoveryVid from "../../../../assets/bio/videos/drug-discovery.mp4";
import immunologyVid from "../../../../assets/bio/videos/immunology.mp4";
import oncologyVid from "../../../../assets/bio/videos/ii-io.mp4";
import nueroscienceVid from "../../../../assets/bio/videos/neuroscience.mp4";
import microbiomeVid from "../../../../assets/bio/videos/microbiome.mp4";
import tissueImagingVid from "../../../../assets/bio/videos/tissue-imaging.mp4";
import medicalImagingVid from "../../../../assets/bio/videos/medical-imaging.mp4";
import patientDataVid from "../../../../assets/bio/videos/patient-data.mp4";


const services = [
    // {
    //     title: "multi-omics",
    //     body: "asdf asdf",
    //     vid: multiOmicsVid,
    //     modalContent: {
    //         type: "science",
    //         content: {
    //             title: "multi-omics",
    //             body: "",
    //         }
    //     }
    // },
    {
        title: "Single Cell Analysis",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        vid: singleCellVid,
    },
    {
        title: "Drug Discovery",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        vid: drugDiscoveryVid,
    },
    // {
    //     title: "immunology",
    //     body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
    //     vid: immunologyVid,
    // },
    {
        title: "Inflammation & Cancer",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        vid: oncologyVid,
    },
    // {
    //     title: "microbiome",
    //     body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
    //     vid: microbiomeVid,
    // },
    // {
    //     title: "neuroscience",
    //     body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
    //     vid: nueroscienceVid,
    // },
    {
        title: "Spatial Data",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        vid: tissueImagingVid,
    },
    // {
    //     title: "medical imaging",
    //     body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
    //     vid: medicalImagingVid,
    // },
    {
        title: "And More...",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        vid: patientDataVid,
    },
];

export default services