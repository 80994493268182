import { Button } from "@mui/material";

const LearnMoreButton = props => {
    return (
        <Button
            sx={{
                ...(props.style),
            }}
            href="#our-services"
            variant="outlined"
            {...props}
        >
            {props.children || "Learn more"}
        </Button>
    )
}


export default LearnMoreButton;
