import { makeStyles } from "@mui/styles";
import {useContext} from "react";
import {EnvironmentContext} from "../../../App/App";
import { Card, Typography } from "@mui/material";

const useStyles = makeStyles({
    ServiceItemCard: props => ({
        // width: props.percent(25),
        width: 280,
        height: 400, //'100%',
        minWidth: 200,
        background: "#FFFFFF",
    }),
    icon: props => ({
        marginBottom: 10,
        width: 100,
    }),
    title: props => ({
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        // fontSize: props.percent(1.6),
        lineHeight: "167.19%",
        // marginTop: 10,
        marginBottom: 10
    }),
    body: props => ({
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        // fontSize: "18px",
        // fontSize: props.percent(1.3),
        lineHeight: "167.19%"
    })
})

const ServiceItemCard = props => {
    const {scaleMinWinDim} = useContext(EnvironmentContext);

    const classes = useStyles({percent: scaleMinWinDim});

    return (
        <Card className={classes.ServiceItemCard}>
            <div style={{ height: 210 }}>
                <img
                    className={classes.icon}
                    src={props.icon}
                    alt={props.title}
                />

                <Typography
                    variant="h5"
                    className={classes.title}
                >
                    {props.title}
                </Typography>
            </div>
            <Typography
                variant="body1"
                className={classes.body}
            >
                {props.body}
            </Typography>

        </Card>
    )
}

export default ServiceItemCard;
