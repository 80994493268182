import Section from "../Section/Section";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";


const ContactSection = () => {
    const theme = useTheme();
    return (
        <Section title={"Contact Us"} style={{ padding: `60px ${theme.spacing(2)}` }}>
            <Grid container spacing={1} justifyContent="space-evenly">
                <Grid item xs={12} md={4}>
                    <ContactInfo/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ContactForm/>
                </Grid>
            </Grid>
        </Section>
    )
}


export default ContactSection;
