import makeStyles from '@mui/styles/makeStyles';
import React, {useContext} from "react";
import {EnvironmentContext} from "../../../App/App";
import careerImages from "./careerImages";


const useStyles = makeStyles({
    CareersCard: props => ({
        display: "flex",
        flexDirection: props.innerWidth > 600 ? "row" : "column",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#1E217C",
        width: "100%",
        height: props.innerWidth > 500 ? 75 : null,
        padding: props.innerWidth > 600 ? 75 : 25,
        paddingLeft: 25,
        // margin: 0,
        marginBottom: props.innerWidth > 600 ? 20 : 10,
        borderRadius: 10,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        boxShadow: "0 0 16px 3px rgba(0,0,0,0.10)",
        transition: "ease 0.4s",

        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
            boxShadow: "0 0 32px 5px rgba(0,0,0,0.05)",
            transition: "ease 0.2s",
            transform: "scale(1.01)",
        },
        // border: "2px solid blue",
    }),
    icon: {
        width: 175,
        height: 175,
        marginRight: 0,
        // marginLeft: -40,
        borderRadius: 10,

    },
    leftTextContainer: {
        width: 600,
        // lineHeight: 0.1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        textAlign: "left",
        marginLeft: 50,
        "& p": {
            fontSize: 20,
            // fontFamily: "Montserrat",
            // fontStyle: "normal",
        }
    },
    rightTextContainer: props => ({
        width: 600,
        // lineHeight: 0.1,
        display: "flex",
        flexDirection: "column",
        alignItems: props.innerWidth > 600 ? "flex-end" : "center",
        justifyContent: "flex-start",
        textAlign: "right",
        marginLeft: 50,
        "& p": {
            fontSize: 20,
            // fontFamily: "Montserrat",
            // fontStyle: "normal",
        }
    })
});

const getHtmlDescription = careerData => {
    // generate hmtl for description section of google tag
}

const CareerCard = props => {
    const {innerWidth} = useContext(EnvironmentContext);
    const classes = useStyles({...props, innerWidth: innerWidth});
    let img = careerImages[props.icon];
    img = img ? img : careerImages["default"];
    const fullTitle = [props.title, props.subtitle].join(" - ");
    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.textContent = JSON.stringify({
        "@context": "https://nucleate.bio",
        "@type": "JobPosting",
        title: fullTitle,
        datePosted: props.datePosted || "2021-07-24",
        description: getHtmlDescription(props),
        image: "https://nucleate.bio/logo192.png",
        workHours: 'Flexible',
        employmentType : props.type.toUpperCase(),
        jobLocationType: "TELECOMMUTE",
        applicantLocationRequirements: {
            "@type": "Country",
            "name": "USA"
        },
        hiringOrganization: {
            '@type': 'Organization',
            name: "Nucleate Biosciences",
            sameAs: "https://nucleate.bio",
            logo: "https://nucleate.bio/logo192.png",
        },
    });
    document.head.appendChild(script);
    return (
        <div className={classes.CareersCard} onClick={props.onClick}>
            <img src={img} className={classes.icon} alt={""}/>
            <div className={classes.leftTextContainer}>
                <h1 style={{marginBottom: 0}}>{props.title}</h1>
                <h2 style={{color: "#695CC1"}}>{props.subtitle}</h2>
                {/*<p> </p>*/}
            </div>
            <div className={classes.rightTextContainer} innerWidth={props.innerWidth}>
                <h2>{props.department}</h2>
                <h2 style={{color: "#695CC1"}}>{props.type}</h2>
                {/*<h2> </h2>*/}

            </div>
        </div>
    )
};

export default CareerCard;