import makeStyles from '@mui/styles/makeStyles';
import {useContext} from "react";
import {EnvironmentContext} from "../../../../App/App";

const useStyles = makeStyles({
    ServiceItemCard: props => ({
        // width: "372px",
        // height: "457px",
        // width: props.percent(20),
        // height: props.percent(25),
        width: "50%",
        left: "276px",
        top: "1528px",
        // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.09)",
        borderRadius: "12px",
        padding: 20,  // TODO these 2 for vertical spacing
        margin: 5,
        textAlign: props.index % 2 === 0 ? "right" : "left",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // boxSizing: "border-box",

        // border: "2px solid blue"
    }),
    title: props => ({
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        // fontSize: "24px",
        fontSize: 28,
        lineHeight: "167.19%",
        // marginTop: 10,
        marginBottom: 10
    }),
    body: props => ({
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        // fontSize: "18px",
        fontSize: 18,
        lineHeight: "167.19%"
    })
})

const ScienceCardDescriptor = props => {

    const {scaleMinWinDim} = useContext(EnvironmentContext);

    const classes = useStyles({percent: scaleMinWinDim, index: props.index});

    return (
        <div className={classes.ServiceItemCard}>


            <h5
                className={classes.title}>
                {props.title}
            </h5>
            <p
                className={classes.body}>
                {props.body}
            </p>

        </div>
    )
}

export default ScienceCardDescriptor;