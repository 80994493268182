import Section from "../Section/Section";
import FooterBackdrop from "./FooterBackdrop";
import { Grid, Link, Typography } from "@mui/material";
import facebookIcon from "../../../assets/icons/facebook.svg";
import logoAndTitleImage from "../../../assets/icons/SoftwareDevelopment/Title_Logo_NucleateSoftware_White_LightOrange.png"

const Footer = () => (
    <Section padding={20}>
        <FooterBackdrop />
        <div style={{position: "relative"}}>
            <Grid container justifyContent="space-between" spacing={8} paddingX={5} textAlign="left">
                <Grid item md={3} sm={1} xs={12}>
                    <img
                        width={150}
                        src={logoAndTitleImage}
                        alt="Nucleate Software"
                    />
                </Grid>
                {/*<Grid item md={3} xs={12}>*/}
                {/*    <Typography*/}
                {/*        color="white"*/}
                {/*        fontWeight="bold"*/}
                {/*        variant="subtitle1"*/}
                {/*    >*/}
                {/*        Services*/}
                {/*    </Typography>*/}
                {/*    <br/>*/}
                {/*    <Typography*/}
                {/*        color="white"*/}
                {/*        variant="subtitle2"*/}
                {/*        component={Link}*/}
                {/*        to="/TEST2"*/}
                {/*    >*/}
                {/*        Mobile development*/}
                {/*    </Typography>*/}
                {/*    <br/>*/}
                {/*    <Typography*/}
                {/*        color="white"*/}
                {/*        variant="subtitle2"*/}
                {/*        component={Link}*/}
                {/*        to="/TEST2"*/}
                {/*    >*/}
                {/*        Web App Development*/}
                {/*    </Typography>*/}
                {/*</Grid>*/}
                <Grid item md={3} xs={12}>
                    <Typography
                        color="white"
                        fontWeight="bold"
                        variant="subtitle1"
                    >
                        Navigation
                    </Typography>
                    <br/>
                    <Link href={"/#our-services"}>
                        <Typography
                            color="white"
                            variant="subtitle2"
                        >
                            Services
                        </Typography>
                    </Link>
                    <br/>
                    <Link href={"/#our-process"}>
                        <Typography
                            color="white"
                            variant="subtitle2"
                        >
                            Process
                        </Typography>
                    </Link>
                </Grid>
                <Grid item md={3} xs={12} justifyContent="center" alignItems="center">
                    <a href="https://www.linkedin.com/company/nucleate-ai/">
                        <img rel="/IMG" width={40} src={facebookIcon} alt="facebook"/>
                    </a>
                </Grid>
            </Grid>
        </div>
    </Section>
)

export default Footer;
