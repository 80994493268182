import makeStyles from  "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
    SectionHeader: props => ({
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "80%",
        margin: 0,
        marginBottom: 40,
        color: props.color || "#7C38F4",
    }),
})

const SectionTitle = props => {
    const classes = useStyles(props);
    return (
        <Typography variant="h3" className={classes.SectionHeader}>
            {props.children}
        </Typography>
    )
}

export default SectionTitle;
