import browserGearIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/browserGear.png";
import chartIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/analyticsChart.png";
import designIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/design.png";
import networkGlobeIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/networkGlobe.png";
import cloudNetworkIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/cloudNetwork.png";
import apiIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/apiCloudGear.png";

const services = [
    {
        title: "Mobile, Web, & Desktop Apps",
        body: "Built to your specification, on any platform. need a cross-platform solution? No problem!\n",
        icon: browserGearIcon,
    },
    {
        title: "Dashboard & Analytics",
        body: "Understand your user-base and control your application securely through a custom admin dashboard.\n",
        icon: chartIcon,
    },
    {
        title: "UX/UI Design",
        body: "Don't have a design? We'll make sure you get a design you love before we start building.\n",
        icon: designIcon,
    },
    {
        title: "Automation & Web Crawling",
        body: "Automate data collection, customer outreach and more.\n",
        icon: networkGlobeIcon,
    },
    {
        title: "Cloud Infrastructure",
        body: "Fast, secure, and scalable infrastructure that you can rely on.\n",
        icon: cloudNetworkIcon,
    },
    {
        title: "Databases & APIs",
        body: "Flexible and fast data solutions to meet the needs of your application.\n",
        icon: apiIcon,
    },
]

export default services
