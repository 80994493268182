import { motion, useTransform, useViewportScroll } from "framer-motion";
import makeStyles from "@mui/styles/makeStyles";
import ContactUsButton from "../HomePage/Buttons/ContactUsButton";
import { Link, useMediaQuery } from "@mui/material";
import LogoAndTitle from "../Branding/LogoAndTitle";
import { useTheme } from "@mui/styles";

const sectionStyleBase = {
    display: "flex",
    alignItems: "center",
}


const useStyles = makeStyles({
    Navbar: {
        width: "100vw",
        height: 70,
        position: "fixed",
        zIndex: 1000,
        boxSizing: "border-box",
        padding: 0,
    },
    background: {
        background: `linear-gradient(225deg, rgb(98, 49, 239) 0%, rgb(153, 63, 248) 85%)`,
        width: "100%",
        height: "100%",
        margin: 0,
        position: "absolute"
    },
    content: {
        position: "absolute",
        padding: "0px 30px",
        width: "100vw",
        height: "100%",
        margin: 0,
        display: "flex",
        boxSizing: "border-box",
        justifyContent: 'space-between',
    },
    titleSection: {
        ...sectionStyleBase,
        justifyContent: "flex-start",
        width: 250,
    },
    middleSection: {
        ...sectionStyleBase,
        justifyContent: "center",
    },
    rightSection: {
        ...sectionStyleBase,
        justifyContent: "flex-end",
        width: 220,
    },
    NavLink: {
        textDecoration: "none",
        margin: "0 50px"
    },
    navLinkText: {
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "167.19%",
        color: "white",
    }
})

const NavLink = props => {
    const classes = useStyles();

    return (
        <Link className={classes.NavLink} href={props.to}>
            <h3 className={classes.navLinkText}>
                {props.children}
            </h3>
        </Link>
    )
}

const Navbar = props => {
    const {scrollY} = useViewportScroll();
    const opacity = useTransform(scrollY, [0, 200, 500], [0, 1, 1])
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    const classes = useStyles({...props, opacity});

    return (
        <motion.div className={classes.Navbar}>
            <motion.div className={classes.background} animate={{opacity}}/>
            <div className={classes.content}>
                <div className={classes.titleSection}>
                    <LogoAndTitle/>
                </div>
                {!isSmall && (
                    <>
                        <div className={classes.middleSection}>
                            <NavLink to={"/#our-services"}>Services</NavLink>
                            <NavLink to={"/#our-process"}>Process</NavLink>
                        </div>
                        <div className={classes.rightSection}>
                            <ContactUsButton size="small"/>
                        </div>
                    </>
                )}
            </div>

        </motion.div>
    )
}


export default Navbar
