import makeStyles from '@mui/styles/makeStyles';
import {Button} from "@mui/material";
import {minWinDimPercent, scaleCssSize} from "../../../../BigBoyFunctions";
import {useContext} from "react";
import {EnvironmentContext} from "../../../App/App";
import { Link, animateScroll as scroll } from "react-scroll";
import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'


const useStyles = makeStyles({
    LearnMoreButton: props => {
        const width = props.width || props.size || props.percent(25);
        const height =  props.height || props.size / 5 || props.percent(6);

        return ({
            scrollBehavior: "smooth",
            background: props.background || "transparent",
            color: props.color || "#695CC1",
            width,
            height,
            borderRadius: scaleCssSize(height, 0.5),
            border: props.border || "2px solid #695CC1",
            margin: props.margin || 20,

            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: scaleCssSize(height, 0.3),
            lineHeight: "86%",

            ...(props.style),
            // ...props
        })
    }
})


const LearnMoreButton = props => {
    const {scaleMinWinDim} = useContext(EnvironmentContext);

    const classes = useStyles({...props, percent: scaleMinWinDim});

    return (
        <AnchorLink href='#learn-more' style={{textDecoration: "none"}} offset="75">
            <Button
                className={classes.LearnMoreButton}
                {...props}
                // href={"#learn-more"}
            >
                {/*<Link*/}
                {/*    activeClass="active"*/}
                {/*    to="learn-more"*/}
                {/*    spy={true}*/}
                {/*    smooth={true}*/}
                {/*    offset={-70}*/}
                {/*    duration={500}*/}
                {/*/>*/}
                {props.children || "Learn more"}
            </Button>
        </AnchorLink>

    )
}


export default LearnMoreButton;